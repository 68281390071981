img {
  width: 100%;
}

.App-header {
  background: rgb(6, 6, 6);
  background: linear-gradient(
    25deg,
    rgba(6, 6, 6, 1) 0%,
    rgba(75, 75, 75, 1) 100%
  );
  padding: 4rem;
  padding-top: 8rem;
  /* min-height: 100vh; */
  width: 1200px;
  max-width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logoUnderline {
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #8a6031, #f9f1a9 50%, #8a6031 100%);
  margin-top: 50px;
}
