.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  /* padding: 8rem; */
}
.App > * {
  max-width: 1200px;
}

.App-link {
  color: #61dafb;
}
